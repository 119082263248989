import { NAME as HeaderName } from '@spa-ec/components/Header/Header.theme';
import { NAME as DesktopHeaderName, HeaderComponents } from '@spa-ec/components/Header/DesktopHeader.theme';
import { NAME as ContentPageName } from '@spa-ec/routes/ContentPage/ContentPage.theme';
import { NAME as PageTitleName } from '@spa-ec/components/StaticPageComponents/PageTitle.theme';
import { NAME as CartTableName } from '@spa-ec/displayComponents/CartTable/CartTable.theme';
import { NAME as ProductRowName } from '@spa-ec/components/ProductTable/Rows/ProductRow.theme';
import theme from './theme.conf';
const contentPageTheme = {
    showSideNavigationOnStaticPages: true,
    showTitleOnContentPageDesktop: true,
    showTitleOnContentPageMobile: true,
    sideContentWidth: '22%',
    sideContentPadding: 'px-2',
};
const headerTheme = {
    showCountrySelector: false,
};
const desktopHeaderTheme = {
    headerComponents: [
        HeaderComponents.SITE_LOGO,
        HeaderComponents.CUSTOMER_TYPE_SELECTOR,
        HeaderComponents.PROMOTIONS,
        HeaderComponents.SEARCH,
        HeaderComponents.MY_ACCOUNT_AND_CART,
    ],
};
const pageTitleTheme = {
    noBorder: true,
    fg: theme.palette.inkmannBlack,
    bg: 'white',
    showLogoutIcon: false,
    combineSections: true,
    bottomBorderColor: '#343434',
};
const cartTableTheme = {
    showProductDetails: true,
    showPerKronaInCartTable: false,
};
const productRowTheme = {
    productCodeColor: theme.col.pale.alt,
};
export default {
    [ContentPageName]: contentPageTheme,
    [HeaderName]: headerTheme,
    [DesktopHeaderName]: desktopHeaderTheme,
    [PageTitleName]: pageTitleTheme,
    [CartTableName]: cartTableTheme,
    [ProductRowName]: productRowTheme,
};
